.searchTagWrapper {
  display: flex;
}

.searchTagWrapper.noneSpacing {
  margin-bottom: 0;
  margin-right: 0;
}

.searchTagWrapper.xSmallSpacing {
  margin-bottom: 9px;
  margin-right: 9px;
}

.searchTag {
  white-space: nowrap;
  color: #888;
  background: #fff;
  border-radius: 16px;
  align-items: center;
  max-width: 100%;
  display: flex;
}

.searchTagEnabled {
  color: #000;
  background: #fbfbfb;
}

.searchTagLabel {
  min-width: 0;
  padding: 4px 0 4px 9px;
  font-size: 14px;
  font-weight: 300;
}

button.deleteButton {
  cursor: not-allowed;
  justify-content: center;
  align-items: center;
  margin: 0 4.5px;
  padding: 4.5px;
  display: flex;
}

button.deleteButton svg {
  fill: #888;
  width: 12px;
  height: 12px;
}

button.deleteButtonEnabled {
  cursor: pointer;
}

button.deleteButtonEnabled:hover .closeIcon {
  fill: #000;
}

.searchTagActive {
  background: #f3f3f3;
}

.noDeleteButton {
  padding-right: 9px;
}
