.answer {
  background: #fbfbfb;
  align-self: start;
  margin-bottom: 27px;
  padding: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.answer:only-child, .answer:last-child {
  margin-bottom: 0;
}
