.author {
  align-items: center;
  margin-bottom: 18px;
  display: flex;
}

.authorName {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.authorLogo, .expertLogo {
  object-fit: contain;
  background: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 18px;
}

.expertLogo {
  fill: #fff;
  background: #000;
  padding: 9px;
}

.authorInfo {
  flex-direction: column;
  display: flex;
}
