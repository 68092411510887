.tileWrapper {
  min-width: 0;
}

.favoritesWrapper {
  z-index: 10;
  margin-top: 0;
  padding: 9px;
  position: absolute;
  top: 0;
  right: 0;
}

.heartIconWrapper {
  transform: scale(.8);
}

.heartIconWrapper.showLightBox {
  margin-top: 0;
}
