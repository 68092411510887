.topSection {
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
  margin-bottom: 18px;
}

.middleSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.wrapper {
  margin-top: 27px;
}

.appliedFiltersWrapper {
  flex: 1;
}

.sortAndPriceContainer {
  display: flex;
  align-items: center;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.label {
  margin: 0 18px 0 36px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
}