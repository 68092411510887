.container {
  flex-direction: column;
  width: 100%;
  min-width: 0;
  display: flex;
}

.bottomModule {
  align-self: center;
  max-width: 1018px;
}

@media (width >= 569px) {
  .bottomModule {
    margin: 54px auto 36px;
  }
}

.headerLeftWrapper {
  flex-direction: column;
  margin: 18px 0 0;
  display: flex;
}

.headerLeft {
  align-items: center;
  display: flex;
}

.spacer {
  margin-top: 18px;
}

.mainContent {
  margin-top: 18px;
  display: flex;
}
