@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.tileContainer {
  grid-template-columns: repeat(6, 1fr);
  align-items: stretch;
  gap: 18px;
  margin-top: 45px;
  display: grid;
}

.tile {
  border: 1px solid #ddd;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  text-decoration: none;
  display: flex;
}

@media (width >= 769px) {
  .tile {
    position: relative;
  }

  .tile:after {
    content: "";
    opacity: 0;
    background: #c2a661;
    width: 100%;
    height: 100%;
    transition: opacity .3s;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .tile:hover:after {
    opacity: .25;
  }
}

.image {
  max-width: 100%;
  max-height: 130px;
}

.title {
  letter-spacing: -.5px;
  text-align: center;
  margin: auto;
  padding-top: 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}
