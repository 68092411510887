/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.container {
  margin-bottom: 36px;
}
@media (max-width: 768px) {
  .container {
    margin-bottom: 18px;
  }
}

.topSpacer {
  border-top: 1px solid #f3f3f3;
}
@media (max-width: 768px) {
  .topSpacer {
    border-top: 9px solid #f3f3f3;
  }
}

.header {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin-top: 36px;
  text-align: center;
}
@media (max-width: 768px) {
  .header {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.5px;
    margin-top: 18px;
  }
}

.viewMoreSection {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .viewMoreSection {
    margin-top: 9px;
  }
}

.viewMoreButton {
  width: 200px;
}
@media (max-width: 768px) {
  .viewMoreButton {
    width: 100%;
  }
}

.itemGrid {
  display: flex;
  flex-wrap: wrap;
  margin: 36px 0 0;
}
@media (max-width: 768px) {
  .itemGrid {
    margin: 0;
  }
}

.respItemGrid {
  flex: 0 0 25%;
  padding: 0 9px;
}

.disablePadding {
  padding: 0;
}

.mobileItemGrid {
  position: relative;
  flex-basis: 50%;
  padding: 18px 9px;
}

.swiperItemFavorites {
  padding: 0 !important;
}

.hideTile {
  display: none;
}