.container {
  padding: 2px;
  position: relative;
  border-bottom: 1px solid #ddd;
}

.facetContent {
  background: inherit;
}
.facetContent[data-state=collapsed] {
  visibility: hidden;
}
.facetContent[data-state=expanded] {
  overflow: visible;
}

.facetContainer {
  margin-left: 2px;
}

.titleWrapper {
  padding: 18px 0 9px !important;
  color: #222;
}
.titleWrapper:hover {
  color: #222;
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  flex-basis: 100%;
  color: #222;
}
.title:hover {
  color: #222;
}

.button {
  display: block;
  padding-bottom: 18px;
}

.abTestSelectedValue {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  color: #222;
  text-transform: none;
}
.abTestSelectedValue .dimension {
  padding-left: 3px;
}