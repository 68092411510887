.container {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

.background {
  background: #f3f3f3;
}

.padding {
  padding-left: 18px;
  padding-right: 18px;
}
