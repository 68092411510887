.topSection {
  justify-content: space-between;
  margin-bottom: 18px;
  padding-bottom: 18px;
  display: flex;
}

.middleSection {
  justify-content: space-between;
  margin-bottom: 18px;
  display: flex;
}

.wrapper {
  margin-top: 27px;
}

.appliedFiltersWrapper {
  flex: 1;
}

.sortAndPriceContainer {
  align-items: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: flex;
}

.label {
  margin: 0 18px 0 36px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}
