.filterItemWrapper {
  margin-right: 9px;
  margin-bottom: 9px;
}

.container {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 9px;
}

.value {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.closeIconWrapper {
  width: 12px;
  height: 12px;
  flex: 0 0 12px;
  margin-left: 9px;
  cursor: pointer;
  color: #888;
}

.truncateLabel {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  white-space: pre;
  flex: 1;
}

.colorDot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}