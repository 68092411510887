.wrapper {
  width: 260px;
  margin-right: 54px;
  flex-shrink: 0;
  padding-bottom: 45px;
  position: relative;
}

.topVisibilityOffset {
  top: -99px;
  position: relative;
}

.menu {
  position: sticky;
  top: 99px;
  height: calc(100vh - 126px);
  width: 280px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 18px;
  padding-bottom: 18px;
}
.menu::-webkit-scrollbar {
  display: none;
}
.menu:hover {
  overflow-y: overlay;
}
.menu:hover::-webkit-scrollbar {
  display: initial;
  -webkit-appearance: none;
  width: 6px;
}
.menu:hover::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ddd;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.scrollBar {
  overflow-y: overlay;
}
.scrollBar::-webkit-scrollbar {
  display: initial;
  -webkit-appearance: none;
  width: 6px;
}
.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ddd;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.fullHeight {
  height: 100%;
  overflow: visible;
}