.paginationList {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 54px 0 18px;
}
@media (min-width: 1025px) {
  .paginationList {
    margin: 54px 0 36px;
  }
}

.paginationItem {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  margin-right: 9px;
}
@media (min-width: 1025px) {
  .paginationItem {
    margin-right: 18px;
  }
}
.paginationItem:last-of-type {
  margin: 0;
}
.paginationItem.page {
  width: 48px;
}
.paginationItem.arrowWrapper {
  position: relative;
}