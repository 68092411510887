.author {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.authorName {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.authorLogo, .expertLogo {
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 18px;
}

.expertLogo {
  background: #000;
  fill: #fff;
  padding: 9px;
}

.authorInfo {
  display: flex;
  flex-direction: column;
}