.wrapper {
  text-align: center;
  width: 70%;
  margin: 27px auto 72px;
}

.links {
  flex-wrap: wrap;
  justify-content: center;
  margin: -9px -4.5px;
  padding-top: 18px;
  display: flex;
}

.link.specify {
  margin: 9px 4.5px;
}
