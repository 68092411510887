.filterItemWrapper {
  margin-bottom: 9px;
  margin-right: 9px;
}

.container {
  place-content: center space-between;
  padding-bottom: 9px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
}

.value {
  text-overflow: ellipsis;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
  overflow-x: hidden;
}

.closeIconWrapper {
  cursor: pointer;
  color: #888;
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  margin-left: 9px;
}

.truncateLabel {
  white-space: pre;
  flex: 1;
  align-items: center;
  display: flex;
  overflow-x: hidden;
}

.colorDot {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: inline-block;
}
