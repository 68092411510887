.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  margin: 18px 0 0;
}

.description {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
.description:not(:last-child),
.description p:not(:last-child) {
  margin: 0 0 18px;
}
.description ul {
  list-style: inside;
  padding-left: 18px;
}