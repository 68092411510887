.item {
  color: #222;
  text-align: center;
  border: 1px solid #bbb;
  width: 100%;
  padding: 9px 0;
  text-decoration: none;
  display: block;
}

.item.isSelected {
  background: #f3f3f3;
}
