.wrapper {
  margin-bottom: 36px;
}

@media (width <= 568px) {
  .wrapper {
    border-top: 9px solid #f3f3f3;
    width: 100vw;
    margin-top: 18px;
    padding: 18px;
    position: relative;
    left: calc(-50vw + 50%);
  }
}

.header {
  letter-spacing: -.5px;
  text-align: center;
  margin: 18px 0 36px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width <= 568px) {
  .header {
    margin: 0 0 18px;
  }
}
