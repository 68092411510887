.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}

.bottomModule {
  max-width: 1018px;
  align-self: center;
}
@media (min-width: 569px) {
  .bottomModule {
    margin: 54px auto 36px;
  }
}

.headerLeftWrapper {
  display: flex;
  flex-direction: column;
  margin: 18px 0 0 0;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.spacer {
  margin-top: 18px;
}

.mainContent {
  display: flex;
  margin-top: 18px;
}