.container {
  margin-bottom: 36px;
}

@media (width <= 768px) {
  .container {
    margin-bottom: 18px;
  }
}

.topSpacer {
  border-top: 1px solid #f3f3f3;
}

@media (width <= 768px) {
  .topSpacer {
    border-top: 9px solid #f3f3f3;
  }
}

.header {
  letter-spacing: -.5px;
  text-align: center;
  margin-top: 36px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width <= 768px) {
  .header {
    letter-spacing: -.5px;
    margin-top: 18px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

.viewMoreSection {
  justify-content: center;
  display: flex;
}

@media (width <= 768px) {
  .viewMoreSection {
    margin-top: 9px;
  }
}

.viewMoreButton {
  width: 200px;
}

@media (width <= 768px) {
  .viewMoreButton {
    width: 100%;
  }
}

.itemGrid {
  flex-wrap: wrap;
  margin: 36px 0 0;
  display: flex;
}

@media (width <= 768px) {
  .itemGrid {
    margin: 0;
  }
}

.respItemGrid {
  flex: 0 0 25%;
  padding: 0 9px;
}

.disablePadding {
  padding: 0;
}

.mobileItemGrid {
  flex-basis: 50%;
  padding: 18px 9px;
  position: relative;
}

.swiperItemFavorites {
  padding: 0 !important;
}

.hideTile {
  display: none;
}
