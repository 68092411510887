.answer {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  align-self: start;
  background: #fbfbfb;
  padding: 18px;
  margin-bottom: 27px;
}
.answer:only-child, .answer:last-child {
  margin-bottom: 0;
}