.container {
  display: flex;
  flex-wrap: wrap;
}

.tileWidth {
  flex-basis: 50%;
  max-width: 50%;
}
@media (min-width: 1025px) {
  .tileWidth.desktop3ItemsRow {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 1025px) {
  .tileWidth.desktop4ItemsRow {
    flex-basis: 25%;
    max-width: 25%;
  }
}