.tileWrapper {
  min-width: 0;
}

.favoritesWrapper {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0;
  padding: 9px;
}

.heartIconWrapper {
  transform: scale(0.8);
}
.heartIconWrapper.showLightBox {
  margin-top: 0;
}