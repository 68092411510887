.popularSearches {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin-top: 27px;
}
@media (max-width: 768px) {
  .popularSearches {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    margin-top: 18px;
  }
}

.popularSearchesCategory {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .popularSearchesCategory {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    font-weight: 600;
  }
}