.question {
  padding: 18px 0;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}
.question:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.questionHeader {
  margin-bottom: 18px;
}

.questionTitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 600;
}
.questionTitle:not(:hover) {
  text-decoration: none;
}

.answerCount {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.button {
  padding-left: 18px;
}