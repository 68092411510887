.container {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.moreFiltersButton {
  cursor: pointer;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

.clearAllButton {
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: 300;
}
