.wrapper {
  max-width: 888px;
  margin: 0 auto 72px;
}

@media (width <= 568px) {
  .wrapper {
    border-top: 9px solid #f3f3f3;
    width: 100vw;
    margin-top: 18px;
    margin-bottom: 0;
    padding: 18px;
    position: relative;
    left: calc(-50vw + 50%);
  }
}

.header {
  letter-spacing: -.5px;
  text-align: center;
  margin: 18px 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (width <= 568px) {
  .header {
    margin: 0 0 18px;
  }
}

.viewMoreButtonWrapper {
  justify-content: center;
  display: flex;
}

.viewMoreButton {
  width: 200px;
  margin-top: 18px;
}

@media (width <= 568px) {
  .viewMoreButton {
    width: 100%;
    margin-top: 0;
  }
}

.hideItem {
  display: none;
}
