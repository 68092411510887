.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.moreFiltersButton {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  cursor: pointer;
}

.clearAllButton {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 9px;
}