.wrapper {
  overflow-x: scroll;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.fullWidthWrapper {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

.innerWrapper {
  flex-wrap: wrap;
  width: fit-content;
  display: flex;
}

@media (width <= 768px) {
  .innerWrapper {
    flex-wrap: nowrap;
  }
}

.fullWidthInnerWrapper {
  padding: 0 18px;
}

.link.specify {
  white-space: nowrap;
  margin: 9px 9px 0 0;
  text-decoration: none;
  display: flex;
}

.link.specify:last-child {
  margin-right: 0;
}
