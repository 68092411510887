.arrowItem {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #222;
  padding: 9px 0;
}
@media (max-width: 568px) {
  :global(.main-content-mobile) .arrowItem {
    position: absolute;
    top: -68px;
  }
  :global(.main-content-mobile) .arrowItem .isLeft {
    left: 0;
  }
  :global(.main-content-mobile) .arrowItem:not(.isLeft) {
    right: 0;
  }
}

.arrow {
  width: 18px;
  height: 18px;
  margin: 0 0 0 9px;
}
.isLeft .arrow {
  margin: 0 9px 0 0;
}