/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  overflow-x: scroll;
}
.wrapper::-webkit-scrollbar {
  display: none;
}

.fullWidthWrapper {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
}

.innerWrapper {
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .innerWrapper {
    flex-wrap: nowrap;
  }
}

.fullWidthInnerWrapper {
  padding: 0 18px;
}

.link.specify {
  margin: 9px 9px 0 0;
  display: flex;
  white-space: nowrap;
  text-decoration: none;
}
.link.specify:last-child {
  margin-right: 0;
}