@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.tileContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: stretch;
  gap: 18px;
  margin-top: 45px;
}

.tile {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 18px;
}
@media (min-width: 769px) {
  .tile {
    position: relative;
  }
  .tile:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.3s ease;
    opacity: 0;
    background: #c2a661;
  }
  .tile:hover:after {
    opacity: 0.25;
  }
}

.image {
  max-height: 130px;
  max-width: 100%;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  text-align: center;
  margin: auto;
  padding-top: 9px;
}