.popularSearches {
  margin-top: 27px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

@media (width <= 768px) {
  .popularSearches {
    justify-content: center;
    margin-top: 18px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 14px;
    font-weight: 300;
    display: flex;
  }
}

.popularSearchesCategory {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

@media (width <= 768px) {
  .popularSearchesCategory {
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 14px;
    font-weight: 600;
  }
}
