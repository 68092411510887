.wrapper {
  padding: 27px;
  border: 1px solid #ddd;
  color: #222;
}
.wrapper:not(:last-child) {
  border-bottom: none;
}
@media (max-width: 768px) {
  .wrapper {
    border-left: none;
    border-right: none;
    padding-left: 0;
  }
  .wrapper:first-child {
    border-top: none;
  }
}

.icon {
  width: 30px;
  height: 20px;
}

.questionWrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 300;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .questionWrapper {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 16px;
    font-weight: 300;
  }
}

.questionWrapperExpanded {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .questionWrapperExpanded {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 16px;
    font-weight: 600;
  }
}

.question {
  max-width: 90%;
}

.answer {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  overflow: hidden;
  height: 0;
}

.answerExpanded {
  padding-top: 18px;
  height: unset;
}

.inherit {
  all: inherit;
}