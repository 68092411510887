.wrapper {
  padding: 36px 0 18px;
}

@media (width >= 569px) {
  .wrapper {
    border: 1px solid #ddd;
    padding: 36px 54px;
  }
}
