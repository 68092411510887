.container {
  flex-wrap: wrap;
  display: flex;
}

.tileWidth {
  flex-basis: 50%;
  max-width: 50%;
}

@media (width >= 1025px) {
  .tileWidth.desktop3ItemsRow {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }

  .tileWidth.desktop4ItemsRow {
    flex-basis: 25%;
    max-width: 25%;
  }
}
