.arrowItem {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #222;
  align-items: center;
  padding: 9px 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  text-decoration: none;
  display: flex;
}

@media (width <= 568px) {
  :global(.main-content-mobile) .arrowItem {
    position: absolute;
    top: -68px;
  }

  :global(.main-content-mobile) .arrowItem .isLeft {
    left: 0;
  }

  :global(.main-content-mobile) .arrowItem:not(.isLeft) {
    right: 0;
  }
}

.arrow {
  width: 18px;
  height: 18px;
  margin: 0 0 0 9px;
}

.isLeft .arrow {
  margin: 0 9px 0 0;
}
