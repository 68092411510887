.wrapper {
  color: #222;
  border: 1px solid #ddd;
  padding: 27px;
}

.wrapper:not(:last-child) {
  border-bottom: none;
}

@media (width <= 768px) {
  .wrapper {
    border-left: none;
    border-right: none;
    padding-left: 0;
  }

  .wrapper:first-child {
    border-top: none;
  }
}

.icon {
  width: 30px;
  height: 20px;
}

.questionWrapper {
  justify-content: space-between;
  width: 100%;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 300;
  display: flex;
}

@media (width <= 768px) {
  .questionWrapper {
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 16px;
    font-weight: 300;
  }
}

.questionWrapperExpanded {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 600;
}

@media (width <= 768px) {
  .questionWrapperExpanded {
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 16px;
    font-weight: 600;
  }
}

.question {
  max-width: 90%;
}

.answer {
  text-align: left;
  height: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
}

.answerExpanded {
  height: unset;
  padding-top: 18px;
}

.inherit {
  all: inherit;
}
