/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  margin-bottom: 36px;
}
@media (max-width: 568px) {
  .wrapper {
    position: relative;
    width: 100vw;
    left: calc((100vw - 100%) / -2);
    margin-top: 18px;
    border-top: 9px solid #f3f3f3;
    padding: 18px;
  }
}

.header {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  text-align: center;
  margin: 18px 0 36px;
}
@media (max-width: 568px) {
  .header {
    margin: 0 0 18px;
  }
}